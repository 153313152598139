<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' +
      clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <!-- Actions List-->
      <action-list :key="clientsStore.client.id" :action-section="3" />

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import ActionList from '@/components/ActionList';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'clientActions',
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    ClientFunctionBar,
    ActionList,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
